import { Box, Flex, HStack, Icon, useColorModeValue } from "@chakra-ui/react";
import { FiLogIn, FiLogOut } from "react-icons/fi";
import Link from "next/link";
import { useUser } from "@auth0/nextjs-auth0/client";

export default function Navbar() {
  const { user } = useUser();

  return (
    <Box
      as="nav"
      role="navigation"
      bg={useColorModeValue("gray.100", "gray.900")}
    >
      <Flex
        h={16}
        px={4}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <HStack spacing={8} alignItems={"center"}>
          <Link href="/access-control">
            Business Matchmaking ™ Access Control
          </Link>
        </HStack>
        <Flex alignItems={"center"}>
          {user ? (
            // eslint-disable-next-line @next/next/no-html-link-for-pages
            <a href="/api/auth/logout">
              <Icon as={FiLogOut} />
            </a>
          ) : (
            // eslint-disable-next-line @next/next/no-html-link-for-pages
            <a href="/api/auth/login">
              <Icon as={FiLogIn} />
            </a>
          )}
        </Flex>
      </Flex>
    </Box>
  );
}
