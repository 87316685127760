import { Center, Container, Stack, Text } from "@chakra-ui/react";
import React from "react";
import { DateTime } from "luxon";

export default function Footer() {
  const year = DateTime.now().year;

  return (
    <Container as="footer" role="contentinfo" py={5}>
      <Stack spacing={{ base: "4", md: "5" }}>
        <Center>
          <Text fontSize="sm" color="subtle">
            &copy; {year} Business Matchmaking ™ | Access Control
          </Text>
        </Center>
      </Stack>
    </Container>
  );
}
