import "@/styles/globals.css";
import type { AppProps } from "next/app";
import { UserProvider } from "@auth0/nextjs-auth0/client";
import { Box, ChakraProvider, Flex, useColorModeValue } from "@chakra-ui/react";
import Navbar from "@/components/Navbar";
import React from "react";
import Footer from "@/components/Footer";

export default function App({ Component, pageProps }: AppProps) {
  return (
    <ChakraProvider>
      <UserProvider>
        <Flex direction="column" h="100vh">
          <Box position="fixed" w="100vw" zIndex="sticky">
            <Navbar />
          </Box>
          <Box flex="1 0 auto" pt="64px">
            <Component {...pageProps} />
          </Box>
          <Box
            flexShrink="0"
            zIndex="sticky"
            bg={useColorModeValue("gray.100", "gray.900")}
          >
            <Footer />
          </Box>
        </Flex>
      </UserProvider>
    </ChakraProvider>
  );
}
